export const WebContent = () =>
  import('@oracle-cx-commerce/bruno-fritsch/src/core/ui/components/widgets/common/WebContent/component');
export {HelloWorld} from './HelloWorld';
export {HeaderWidget} from './header';
export {FooterWidget} from './FooterWidget';
export {FooterWidgetDifor} from './FooterWidgetDifor';
export {TwoMinutesReviewWidget} from './TwoMinutesReviewWidget';
export {SimpleBanner} from './SimpleBanner';
export {SliderBanner} from './SliderBanner';
export {BrandHeader} from './BrandHeader';
export {LegalInformation} from './LegalInformation';
export {Branches} from './Branches';
export {BrandDownloadableFiles} from './BrandDownloadableFiles';
export {BestSellers} from './BestSellers';
export {GenericModal} from './GenericModal';
export {Stepper} from './Stepper';
export {HomeRedirect} from './HomeRedirect';
